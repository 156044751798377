import { useState} from 'react';
 import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';  //npm install react-router-dom@5
 import TheGame from './components/TheGame';
 import Cashier from './components/Cashier';
 import CashierLogin from './components/CashierLogin';
 import AdminPage from './components/AdminPage';
 import AdminDashboard from './components/AdminDashboard';
 import AdminShift from './components/AdminShift';
 import AdminGameStat from './components/AdminGameStat';
 import AdminPeople from './components/AdminPeople';
 import AdminSettings from './components/AdminSettings';
 import AdminLogin from './components/AdminLogin';
 import AdminTickets from './components/AdminTickets';
 import PartnerDashboard from './components/PartnerDashboard';
 import PartnerShift from './components/PartnerShift';
 import PartnerGameStat from './components/PartnerGameStat';
 import PartnerLogin from './components/PartnerLogin';
 import PartnerTickets from './components/PartnerTickets';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function App() {

  return (
     <Router>
    <div className="App" >
       <Switch>
        <Route path ="/monitor" component = {TheGame} />
        <Route path ="/cashier" component = {sessionStorage.getItem('cashier_name') != null ? Cashier : CashierLogin} />
        <Route path ="/admin" component = {sessionStorage.getItem('admin_name') != null ? AdminDashboard : AdminLogin} />
        <Route path ="/adminshift" component = {sessionStorage.getItem('admin_name') != null ? AdminShift : AdminLogin} />
        <Route path ="/admingamestat" component = {sessionStorage.getItem('admin_name') != null ? AdminGameStat : AdminLogin } />
        <Route path ="/adminpeople" component = {sessionStorage.getItem('admin_name') != null ? AdminPeople : AdminLogin } />
        <Route path ="/adminsettings" component = {sessionStorage.getItem('admin_name') != null ? AdminSettings : AdminLogin } />
        <Route path ="/admintickets" component = {sessionStorage.getItem('admin_name') != null ? AdminTickets : AdminLogin } />
        <Route path ="/partner" component = {sessionStorage.getItem('partner_name') != null ? PartnerDashboard : PartnerLogin} />
        <Route path ="/partnershift" component = {sessionStorage.getItem('partner_name') != null ? PartnerShift : PartnerLogin} />
        <Route path ="/partnergamestat" component = {sessionStorage.getItem('partner_name') != null ? PartnerGameStat : PartnerLogin } />
        
        <Route path ="/partnertickets" component = {sessionStorage.getItem('partner_name') != null ? PartnerTickets : PartnerLogin } />
      </Switch> 
    </div>
   </Router>
    
  );
}

export default App;
